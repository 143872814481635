import React, { useContext, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import ThemeContext from "../Context/ThemeContext";
import cssIcon from "../../assets/skill-icons/css-icon.svg";
import firebaseIcon from "../../assets/skill-icons/google-firebase-icon.svg";
import htmlIcon from "../../assets/skill-icons/html-icon.svg";
import jsIcon from "../../assets/skill-icons/javascript-programming-language-icon.svg";
import microIcon from "../../assets/skill-icons/microsoft-dot-net-icon.svg";
import nodeIcon from "../../assets/skill-icons/node-js-icon.svg";
import reactIcon from "../../assets/skill-icons/react-js-icon.svg";
import nextJs from "../../assets/skill-icons/next-js.svg";
import typescriptIcon from "../../assets/skill-icons/typescript-icon.svg";
import expressIcon from "../../assets/skill-icons/express-icon.svg";

import "./Skills.css";

const iconStyle = {
  height: "50px",
  width: "50px",
  marginTop: "2rem",
};
const Skills = () => {
  const { theme } = useContext(ThemeContext);

  const openInNewTab = (url) => {
    console.log("e");
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div style={{}}>
      <Grid
        container
        sx={{
          padding: "4rem",
          borderColor: theme === "light" ? "#1d1e20dc" : "#e0e0eaE6",
        }}
      >
        <div
          className="skill-title"
          style={{
            color: theme === "light" ? "#1d1e20" : "#e0e0ea",
            float: "left",

            textAlign: "left",
            marginBottom: "2rem",
          }}
        >
          Skills Acquired through Self-Exploration and Work Experience
        </div>
        <Grid
          container
          xs={12}
          md={12}
          rowSpacing={1}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Paper
            onClick={() => openInNewTab("https://react.dev/")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={reactIcon} alt="React" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              React Js
            </div>
          </Paper>
          <Paper
            onClick={() => openInNewTab("https://nextjs.org/")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={nextJs} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              Next Js
            </div>
          </Paper>

          <Paper
            onClick={() => openInNewTab("https://en.wikipedia.org/wiki/CSS")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={cssIcon} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              CSS
            </div>
          </Paper>

          <Paper
            onClick={() => openInNewTab("https://en.wikipedia.org/wiki/HTML")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={htmlIcon} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              HTML
            </div>
          </Paper>

          <Paper
            onClick={() => openInNewTab("https://www.javascript.com/")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={jsIcon} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              JavaScript
            </div>
          </Paper>

          <Paper
            onClick={() => openInNewTab("https://www.typescriptlang.org/")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={typescriptIcon} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              TypeScript
            </div>
          </Paper>

          <Paper
            onClick={() => openInNewTab("https://firebase.google.com/")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={firebaseIcon} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              Firebase
            </div>
          </Paper>

          <Paper
            onClick={() => openInNewTab("https://nodejs.org/en")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={nodeIcon} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              Node.js
            </div>
          </Paper>

          <Paper
            onClick={() => openInNewTab("https://expressjs.com/")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={expressIcon} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              Express.js
            </div>
          </Paper>

          <Paper
            onClick={() => openInNewTab("https://dotnet.microsoft.com/en-us/")}
            style={{
              cursor: "pointer",
              margin: ".5rem",
              height: "150px",
              width: "150px",
              borderRadius: "1rem",
              backgroundColor: "transparent",
            }}
          >
            <img style={iconStyle} src={microIcon} alt="Description" />
            <div
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            ></div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Skills;
