import React, { useContext, useEffect } from "react";
import { Fade, Grid } from "@mui/material";
import "./Profile.css";
import ThemeContext from "../Context/ThemeContext";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

const Profile = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div style={{}}>
      <Fade in>
        <Grid className="main-container" container>
          <Grid item xs={12} md={12}>
            <div
              style={{
                color: theme === "light" ? "#1d1e20dc" : "#e0e0eaE6",
              }}
              className="main-hi-text"
            >
              Hello! my name is
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div
              className="main-name-text"
              style={{
                color: theme === "light" ? "#1d1e20" : "#e0e0ea",
              }}
            >
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed once, initially
                  "Lynier",
                  1000,
                  "Sanico",
                  1000,
                  "Enorasa",
                  1000,
                  "Lynier Sanico Enorasa",
                  1000,
                ]}
                speed={50}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div
              className="main-desc-text"
              style={{
                marginBottom: "2rem",
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
              }}
            >
              I'm a web developer and I like to build things online.
            </div>
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Link to="/skills">
              <div
                className="main-projects-text"
                style={{
                  color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
                }}
              >
                {" "}
                • Skills
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} md={2}>
            <div
              className="main-projects-text"
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
              }}
            >
              {" "}
              • Projects
            </div>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <div
              className="main-projects-text"
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
              }}
            >
              {" "}
              • Experiences
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div
              className="main-projects-text"
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
              }}
            >
              {" "}
              • Contact
            </div>
          </Grid>
        </Grid>
      </Fade>
    </div>
  );
};

export default Profile;
