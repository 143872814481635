import React, { useContext, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import { Fade, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ThemeContext from "../Context/ThemeContext";
import "./NavDropDown.css";
const NavDropDown = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Fade in={true}>
        <Grid className="main-container" container>
          <Grid item xs={12} md={12}>
            <Link to="/">
              <div
                className="nav-title-text"
                style={{
                  color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
                }}
              >
                {" "}
                • Home
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} md={12}>
            <Link to="/skills">
              <div
                className="nav-title-text"
                style={{
                  color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
                }}
              >
                {" "}
                • Skills
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} md={12}>
            <Link to="/projects">
              <div
                className="nav-title-text"
                style={{
                  color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
                }}
              >
                {" "}
                • Projects
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} md={12}>
            <div
              className="nav-title-text"
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
              }}
            >
              {" "}
              • Experiences
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div
              className="nav-title-text"
              style={{
                color: theme === "light" ? "#1d1e20C9" : "#e0e0ea79",
              }}
            >
              {" "}
              • Contact
            </div>
          </Grid>
        </Grid>
      </Fade>
    </>
  );
};

export default NavDropDown;
