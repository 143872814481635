import logo from "./logo.svg";
import "./App.css";
import Profile from "./views/profile/Profile";
import NavBar from "./views/navigation/NavBar";
import ThemeContext from "./views/Context/ThemeContext";
import { useEffect, useState } from "react";
import Skills from "./views/skills/Skills";
import { Route, Routes } from "react-router-dom";
import NavDropDown from "./views/navDropDown/NavDropDown";
import Projects from "./views/projects/Projects";

function App() {
  const [theme, setTheme] = useState("light");
  const [pageSeleceted, setPageSelected] = useState("home");

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme, pageSeleceted, setPageSelected }}
    >
      <div
        style={{
          backgroundColor: theme === "dark" ? "#1d1e20" : "#e0e0ea",
          minHeight: "100vh",
        }}
        className="App"
      >
        <NavBar />
        <div
          style={{
            maxWidth: "1080px",
            marginInline: "auto",
            padding: "24px",
          }}
        >
          <Routes>
            <Route path="/nav" element={<NavDropDown />} />
            <Route path="/" element={<Profile />} />
            <Route path="/skills" element={<Skills />} />
            {/* <Route path="/projects" element={<Projects />} /> */}
          </Routes>
        </div>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
{
  /* {pageSeleceted === "home" && <Profile />}
            {pageSeleceted === "skills" && <Skills />} */
}
