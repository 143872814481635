import React, { useContext, useState } from "react";

import ThemeContext from "../Context/ThemeContext";

import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import "./NavBar.css";
import { Link } from "react-router-dom";
import catIcon from "../../assets/skill-icons/cat.svg";

const NavBar = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  function updateLocalTheme() {
    setTheme(theme === "dark" ? "light" : "dark");
  }
  return (
    <div
      style={{
        width: "100%",
        height: "50px",
        paddingTop: "5rem",
      }}
    >
      <div>
        <Link to="/nav">
          <MenuRoundedIcon
            className="burgerMenuIcon"
            sx={{
              cursor: "pointer",
              color: theme === "dark" ? "#e0e0ea" : "#1d1e20",
            }}
          />
        </Link>

        {theme === "dark" ? (
          <LightModeRoundedIcon
            sx={{ cursor: "pointer" }}
            onClick={() => updateLocalTheme()}
            className="sunIcon"
          />
        ) : (
          <DarkModeRoundedIcon
            sx={{ cursor: "pointer" }}
            onClick={() => updateLocalTheme()}
            className="moonIcon"
          />
        )}
      </div>
    </div>
  );
};

export default NavBar;
